<template>

  <div>

    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>

      <form-abstract />

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="submit"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="outline-primary"
          class="ml-2"
          @click="previous"
        >
          Voltar
        </b-button>
        <template
          v-if="this.$store.getters['formMedication/action'] === 'Adicionar novo medicamento favorito'
            || this.$store.getters['formMedication/action'] === 'Adicionar um novo lembrete de medicamento'"
        >
          <b-button
            type="submit"
            variant="primary"
            class="ml-2"
            @click="next"
          >
            Adicionar
          </b-button>
        </template>
        <template v-else>
          <b-button
            type="submit"
            variant="primary"
            class="ml-2"
            :disabled="disableSave"
            @click="edit"
          >
            Salvar
          </b-button>
        </template>
      </b-col>

    </b-card>

  </div>

</template>

<script>
import {
  BCard, BBreadcrumb, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formAbstract from './Form-medication-abstract.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BCol,
    BButton,

    formAbstract,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      disableSave: false,
      items: [
        {
          text: 'Medicamento',
          active: true,
        },
        {
          text: 'Horário',
          active: true,
        },
        {
          text: 'Frequência',
          active: true,
        },
        {
          text: 'Duração',
          active: true,
        },
        {
          text: 'Resumo',
          active: false,
        },
      ],
    }
  },

  mounted() {
    this.link = this.$store.getters['formMedication/link']
  },

  methods: {
    next() {
      if (this.link === 'favorite') {
        this.$store.dispatch('formMedication/saveFavorite')
          .then(() => {
            this.$swal({
              title: 'Sucesso!',
              text: 'O medicamento favorito foi cadastrado e já está disponível',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.isConfirmed) {
                  this.$router.push(this.link)
                }
              })
          },
          () => {
            this.$swal({
              title: 'Erro!',
              text: 'O medicamento não foi cadastrado',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push(this.link)
              }
            })
          })
      } else {
        this.$store.dispatch('formMedication/saveReminder')
          .then(() => {
            this.$swal({
              title: 'Lembrete de medicamento enviado com sucesso!',
              text: 'Agora, seu paciente será notificado sobre o novo lembrete de medicamento.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.isConfirmed) {
                  if (this.link === 'patient') {
                    this.$store.commit('patient/UPDATE_SHOW_TAB', true)
                    this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
                    this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
                    this.$router.push(`${this.link}-edit`)
                  } else {
                    this.$router.push(this.link)
                  }
                }
              })
          },
          () => {
            this.$swal({
              title: 'Erro!',
              text: 'O lembrete não foi cadastrado',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                if (this.link === 'patient') {
                  this.$store.commit('patient/UPDATE_SHOW_TAB', true)
                  this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
                  this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
                  this.$router.push(`${this.link}-edit`)
                } else {
                  this.$router.push(this.link)
                }
              }
            })
          })
      }
    },
    edit() {
      this.disableSave = true
      this.$store.dispatch('formMedication/saveFavorite')
        .then(() => {
          this.$swal({
            title: 'Sucesso!',
            text: 'O medicamento favorito foi cadastrado e já está disponível',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.isConfirmed) {
                if (this.link === 'patient') {
                  this.$store.commit('patient/UPDATE_SHOW_TAB', true)
                  this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
                  this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
                  this.$router.push(`${this.link}-edit`)
                } else {
                  this.$router.push(this.link)
                }
              }
            })
        },
        () => {
          this.$swal({
            title: 'Erro!',
            text: 'O medicamento não foi cadastrado',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              if (this.link === 'patient') {
                this.$store.commit('patient/UPDATE_SHOW_TAB', true)
                this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
                this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
                this.$router.push(`${this.link}-edit`)
              } else {
                this.$router.push(this.link)
              }
            }
          })
        })
    },
    cancel() {
      if (this.link === 'patient') {
        this.$store.commit('patient/UPDATE_SHOW_TAB', true)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
        this.$router.push(`${this.link}-edit`)
      } else {
        this.$router.push(this.link)
      }
    },
    previous() {
      this.$store.commit('formMedication/UPDATE_SHOW_DURATION', false)
      this.$router.push(`${this.link}-duration`)
    },
  },
}
</script>
